import React from 'react';

import { Column, Grid } from '@/components/global/Grid';
import { usePaths } from '@/lib/paths';

import DynamicComponent from '../../storyblok/components/DynamicComponent';
import { IImage } from '../cms/Image/Image';
import Button from '../global/Button';
import styles from './styles.module.scss';

type Props = {
  mainTitle: string;
  secondaryTitle: string;
  buttonText: string;
  image?: Array<IImage>;
};

const Error_404: React.FC<Props> = ({ mainTitle, secondaryTitle, buttonText, image }) => {
  const paths = usePaths();

  return (
    <>
      <main id="main">
        <Grid rowAlign="center">
          <Column sm={12} md={12} lg={5}>
            <div className={styles.container}>
              <h1 className={styles.header}>{mainTitle}</h1>
              <p className={styles.text}>{secondaryTitle}</p>
              <Button className={styles.button} href={paths.$url()}>
                {buttonText}
              </Button>
            </div>
          </Column>
          <Column sm={12} md={4} lg={1}></Column>
          <Column sm={12} md={4} lg={6}>
            {image && image.length > 0 && (
              <div className={styles.containerImage}>
                <DynamicComponent blok={image?.[0]} />
              </div>
            )}
          </Column>
        </Grid>
      </main>
    </>
  );
};

export default Error_404;
