import { GetStaticPropsContext } from 'next';
import React from 'react';

import Error_404 from '@/components/404/404';
import { IImage } from '@/components/cms/Image/Image';
import Footer from '@/components/global/Footer';
import Header from '@/components/global/Header';
import NotFoundSeo from '@/components/seo/NotFoundSeo';
import { VERSION } from '@/lib/constants';
import { getStoryBlokProps } from '@/lib/storyblok/storyblokGetStaticProps';
import { getStoryBlokNavLinks } from '@/lib/storyblok/storyblokNavLinks';

type Content = {
  mainTitle?: string;
  secondaryTitle?: string;
  buttonText?: string;
  image?: Array<IImage>;
};

type Story = {
  content?: Content;
};

interface IProps {
  story?: Story;
}

const Custom404: React.FC<IProps> = ({ story }) => {
  const { mainTitle, secondaryTitle, buttonText, image } = story?.content;

  const globalLinks = getStoryBlokNavLinks(story);
  return (
    <>
      <NotFoundSeo />
      <Header navigation={globalLinks} />
      <Error_404
        mainTitle={mainTitle}
        secondaryTitle={secondaryTitle}
        buttonText={buttonText}
        image={image}
      />
      <Footer navigation={globalLinks.footerLinks} theme="grey-light" />
    </>
  );
};

export const getStaticProps = async (context: GetStaticPropsContext) => {
  const pageSlug = 'global/404';
  const preview = context.preview || false;
  const resolveRelations = 'NotFoundPage.globalHeader,NotFoundPage.globalFooter';
  const story = await getStoryBlokProps({ pageSlug, context, VERSION, resolveRelations });

  return {
    props: {
      preview,
      story: story || null,
      resolve_relations: resolveRelations
    },
    revalidate: 60 * 60 // value in seconds, how often ISR will trigger on the server
  };
};

export default Custom404;
